import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from "primereact/inputtextarea";
import { confirmDialog } from 'primereact/confirmdialog';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import axios from 'axios';



const StokYonetimi = () => {
    const [urunler, setUrunler] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [rowClick, setRowClick] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilterGunluk, setGlobalFilterGunluk] = useState(null);
    const [selectDate, setSelectDate] = useState(formatISODate(new Date()));
    const [note, setNote] = useState("");
    const [dataYenile, SetDataYenile] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [satislar, SetSatislar] = useState(null)
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}Product/GetProductList`).then((response) => {
            if (response != null) {
                setUrunler(response?.data?.data);
            }
        })
    }, [])
    useEffect(() => {
        const endDate = new Date();
        const startDate = new Date();
        endDate.setDate(endDate.getDate() + 1);
        const params = {
            bastar: startDate.toISOString().split('T')[0],
            bittar: endDate.toISOString().split('T')[0]
        };
        axios.get(`${process.env.REACT_APP_API_URL}Product/GetProductList`).then((response) => {
            if (response != null) {
                setUrunler(response?.data?.data);
            }
        });
        axios.get(`${process.env.REACT_APP_API_URL}Stok/GetStockMovements`, { params }).then((response) => {
            if (response != null) {
                SetSatislar(response?.data?.data);
            }
        });
    }, [dataYenile]);
    const stockBodyTemplate = (rowData) => {
        const stockClassName = classNames(' h-2rem  justify-content-center align-items-center text-md  pt-1 ', {
            'bg-pink-100 text-pink-600 ': rowData.quantityPerUnit === 0,
            'bg-blue-100 text-blue-900': rowData.quantityPerUnit > 0 && rowData.quantityPerUnit <= 10,
            'bg-teal-100 text-teal-900': rowData.quantityPerUnit > 10
        });

        return <div className={stockClassName}>{rowData.quantityPerUnit}</div>;
    };
    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <Image src={rowData.images[0]?.name} preview style={{ borderRadius: '27px' }} width="35" />
            </>
        )
    }
    const imageSatisTemplate = (rowData) => {
        return <Image src={rowData.picture} preview style={{ borderRadius: '27px' }} width="35" />

    }

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            console.log(urunler);
            const yeniListe = urunler.map(({ productId, productName, quantityPerUnit }) => ({ productId, productName, quantityPerUnit }));
            const worksheet = xlsx.utils.json_to_sheet(yeniListe);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            workbook.Sheets.data.A1.v = "ÜRÜN NUMARASI";
            workbook.Sheets.data.B1.v = "ÜRÜN ADI";
            workbook.Sheets.data.C1.v = "ANLIK STOK";
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'urunler');
        });
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };


    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center p-0">
            <h5 className="m-0 pl-5">Ürün Listesi</h5>
            <Button
                type="button"
                label='Ürün Stok Listesi'
                icon="pi pi-file-excel"
                className="p-button-success mr-2"
                onClick={exportExcel}
                data-pr-tooltip="XLS" />
            <span className="block mt-1 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ürün Ara..." />
            </span>

        </div>
    );
    const gunluksatisarama = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center p-0">
            <h5 className="m-0 pl-5">Satış Listesi</h5>
            <span className="block mt-1 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilterGunluk(e.target.value)} placeholder="Günlük Satış Ara..." />
            </span>
        </div>
    );


    const secilenheader = (
        <div className="bg-gray-50 p-3">
            <h5 className="m-0">Seçilen Ürün Listesi</h5>

        </div>
    );
    const onOrderDate = (e) => {
        e.setHours(e.getHours() + 4);
        let MovementDate = e.toISOString();
        setSelectDate(MovementDate);
    }
    const kayitOnay = (tip) => {
        confirmDialog({
            message: `Seçilen ürünlerin stok işlemini (${tip}) onaylıyor musunuz ?`,
            header: 'Stok İşlemi',
            icon: 'pi pi-question',
            acceptLabel: 'Evet',
            rejectLabel: "İptal",
            acceptClassName: 'p-button-secondary',
            accept: () => accept(tip),

        });
    };
    const accept = (islemtip) => {
        var list = []
        var error = false;
        selectedProducts.map((item, index) => {
            if (item.quantityPerUnit == 0 || islemtip == '') {
                error = true;
                return;
            }
            const sampleStockMovement = {
                ProductID: item.productId,
                MovementDate: selectDate,
                MovementType: islemtip,
                Quantity: item.quantityPerUnit,
                UnitPrice: item.unitPrice,
                CreatedAt: null,
                UpdatedAt: null,
                Note: note
            };
            list.push(sampleStockMovement)
        });
        if (error) {
            list = [];
            toast.current.show({ severity: 'error', summary: 'İşlem Hatalı', detail: 'Ürün adeti sıfır olamaz. Adetleri kontrol ediniz', life: 5000 });
            return;
        }

        axios.post(`${process.env.REACT_APP_API_URL}Stok/AddStockMovements`, list).then((response) => {
            toast.current.show({ severity: 'success', summary: 'İşlem Başarılı', detail: `Stok İşlemi (${islemtip}) Yapıldı.`, life: 3000 });
            setSelectedProducts([])
            SetDataYenile(!dataYenile)
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: `Başarısız (${islemtip})`, detail: error.message, life: 3000 });
        });
    }
    const buttonmenu = (
        <div className='bg-surface-50 p-3 float-right' >
            <Toast ref={toast} position="bottom-right" />
            <div className='row grid '>
                <div className="field col-12 md:col-4">
                    <span htmlFor="shipDate">Tarih : </span>
                    <Calendar id="shipDate" placeholder='Tarih Seçiniz ' value={new Date()} onChange={(e) => onOrderDate(e.target.value)} style={{ width: "80%" }} dateFormat="dd.mm.yy" />
                </div>
                <div className="field col-12 md:col-8">
                    <InputTextarea value={note} onChange={(e) => setNote(e.target.value)} placeholder='Bu alana satış ile ilgili not girebilrisiniz' id='not' style={{ width: "100%" }} rows={1} cols={30} />
                </div>
            </div>

            <Button label="Satış Giriş"
                disabled={selectedProducts.length > 0 ? false : true}
                icon="pi pi-bolt"
                onClick={(e) => kayitOnay('Satış')}

                className="p-button p-button-success" />
            <Button label="Stok Girişi"
                icon="pi pi pi-arrow-right"
                onClick={(e) => kayitOnay('Stok Girişi')}
                disabled={selectedProducts.length > 0 ? false : true}
                className="p-button p-button-warning ml-2"
            />

        </div>
    );
    const priceBodyTemplate = (rowData) => {
        return new Intl.NumberFormat("en-US").format(rowData.quantityPerUnit);
    };
    const priceEditor = (options) => {
        return <InputNumber className="p-inputtext-sm" decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-secondary" value={options.value} onValueChange={(e) => options.editorCallback(e.value)} mode="decimal" showButtons max={1000000000} />
    };
    const onRowEditComplete = (e) => {
        let _products = [...selectedProducts];
        let { newData, index } = e;

        _products[index] = newData;

        setSelectedProducts(_products);
    };
    const islemtipi = (rowData) => {
        if (rowData.movementType == "Satış")
            return <Tag severity="success" icon="pi pi-check" value={rowData.movementType}></Tag>
        if (rowData.movementType == "Stok Girişi")
            return <Tag severity="warning" icon="pi pi-arrow-right" value={rowData.movementType}></Tag>


    }
    const islemiptali = (rowData) => {
        return <Button icon="pi pi-times" onClick={() => islemIptalClick(rowData)} className='p-button-danger' label='' aria-label="Cancel" />
    }
    const islemIptalClick = (islem) => {
        console.log(islem.movementID);
        axios.post(`${process.env.REACT_APP_API_URL}Stok/IptalStockMovements`, { movementID: islem.movementID }).then((response) => {
            if (response?.data.status == 200) {
                setSelectedProducts([])
                SetDataYenile(!dataYenile)
                toast.current.show({ severity: 'success', summary: `İşlem İptali`, detail: "İşlem iptal edildi.Stok tekrar düzenlendi.", life: 5000 });
            } else {
                console.log("Veritabanında hata oldu", response)
                toast.current.show({ severity: 'warn', summary: `Başarısız İşlem.`, detail: "İşlem Başarısız", life: 3000 });
            }

        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: `Başarısız İşlem.`, detail: error.message, life: 3000 });
        });

    }
    function formatISODate(date) {
        return date.toISOString();
    }
    const satisDate = (rowData) => {
        return (
            <>
                {new Date(rowData.movementDate).toLocaleDateString()}
            </>
        );
    }
    const rowClassName = (rowData) => {
        return { 'p-highlight': (rowData.movementType === "Stok Girişi") };
    }
    return (
        <div className="grid crud-demo no-gutters">
            <div className="col-6">
                <div className="card p-2 bg-blue-100 ">

                    <DataTable
                        className="datatable-responsive"
                        globalFilter={globalFilter}
                        size='small'
                        header={header}
                        emptyMessage="Ürün bulunamamıştır."
                        responsiveLayout="scroll"
                        rows={7}
                        rowsPerPageOptions={[7, 20, 25, 30, 100]}
                        loading={urunler.length == 0 ? true : false}
                        showGridlines
                        paginator
                        ref={dt}
                        value={urunler}
                        selectionMode={rowClick ? null : 'checkbox'}
                        selection={selectedProducts}
                        onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="productId"                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '1rem' }}></Column>
                        {/* <Column header="Resim" bodyStyle={{ textAlign: 'center' }} headerStyle={{ width: '1rem' }} body={imageBodyTemplate}></Column> */}
                        <Column field="productName" header="Ürün Adı" sortable></Column>
                        <Column field="quantityPerUnit" sortable bodyStyle={{ textAlign: 'center' }} header="Stok" body={stockBodyTemplate} ></Column>
                        {/* <Column field="productId"  body={(e) => e.unitPrice} header="Fiyat"></Column> */}
                    </DataTable>


                </div>
            </div >
            <div className="col-6">
                <div className="card p-2 bg-green-50">
                    {secilenheader}
                    <DataTable
                        className="datatable-responsive "
                        emptyMessage="İşlem yapılacak ürün bulunmamaktadır."
                        paginator
                        responsiveLayout="scroll"
                        rows={7}
                        rowsPerPageOptions={[7, 20, 25, 30, 100]}
                        stripedRows
                        editMode="row"
                        rowHover
                        size='small'
                        value={selectedProducts}
                        onRowEditComplete={onRowEditComplete}
                        id="productId" >
                        <Column headerStyle={{ width: '1rem' }} field="productId" header="#"></Column>
                        <Column header="Resim" bodyStyle={{ textAlign: 'center' }} body={imageBodyTemplate}></Column>
                        <Column field="productName" header="Ürün Adı"></Column>
                        <Column field="quantityPerUnit" bodyStyle={{ textAlign: 'center' }} header="Satış Adet" body={priceBodyTemplate} editor={(options) => priceEditor(options)} style={{ width: '20%' }}></Column>
                        <Column rowEditor="true" headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                    </DataTable>
                    {buttonmenu}
                </div>
            </div >
            <div className="col-12">
                <div className="card p-3 bg-green-50">
                    <DataTable
                        lassName="datatable-responsive"
                        size='small'
                        emptyMessage="Ürün bulunamamıştır."
                        responsiveLayout="scroll"
                        rows={10}
                        rowsPerPageOptions={[10, 30, 100]}
                        showGridlines
                        paginator
                        stripedRows
                        header={gunluksatisarama}
                        rowHover
                        globalFilter={globalFilterGunluk}
                        value={satislar}
                        onRowEditComplete={onRowEditComplete}
                        rowClassName={rowClassName}
                        id="productId" >
                        <Column headerStyle={{ width: '1rem' }} field="productID" header="#"></Column>
                        <Column header="Resim" bodyStyle={{ textAlign: 'center' }} headerStyle={{ width: '5%' }} body={imageSatisTemplate}></Column>
                        <Column field="productName" header="Ürün Adı"></Column>
                        <Column body={islemtipi} header="İşlem Tipi" bodyStyle={{ textAlign: 'center' }} headerStyle={{ width: "10%", textAlign: 'center' }}></Column>
                        <Column field="quantity" header="Satış Adet" bodyStyle={{ textAlign: 'center' }} headerStyle={{ width: "10%", textAlign: 'center' }}   ></Column>
                        <Column body={satisDate} header="Satış Tarihi" headerStyle={{ width: "10%", textAlign: 'center' }}></Column>
                        <Column field="not" header="İşlem Notu" headerStyle={{ width: "23%", textAlign: 'center' }}></Column>
                        <Column header="İşlem İptal" bodyStyle={{ textAlign: 'center' }} body={islemiptali} headerStyle={{ width: "8%", textAlign: 'center' }}></Column>
                    </DataTable>

                </div>

            </div>
        </div >
    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(StokYonetimi, comparisonFn);
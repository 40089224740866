import React, { useRef } from "react";
import "./styles.css";
import Pdf from "react-to-pdf";
import { useReactToPrint } from "react-to-print";
import { Button } from 'primereact/button';

const SiparisFisi = (props) => {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })


    const { siparis } = props;

    const egcTeknik = {
        name: "-",
        address: "İnönü Mahallesi 1754. Sokak No:7 Yenimahalle/Ankara",
        phone: "0331 973 73 93",
        shipmentType: "Ücret Gönderici Ödemeli"

    }

    console.log("sipariş ne ", siparis);

    return (
        <div className="card" style={{ border: "2px solid black" }} >
            <div className="invoice-box" ref={componentRef}>

                <div className="title">
                    <img src="assets/layout/images/logo-4.png" height={"90px"} alt="logo" />
                </div>
                <hr style={{ border: "2px solid black" }} />
                <div className="col-12 md:col-12 ">
                    <span style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>Alıcı&emsp;&emsp;&emsp;&ensp;&emsp; :&ensp;{siparis.listOrderDetail
                    [0]?.ordered?.firstName.toUpperCase() + " " + siparis?.listOrderDetail
                    [0]?.ordered?.lastName.toUpperCase()}</span>
                    <br />
                    <span style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;:&ensp;{siparis.listOrderDetail
                    [0]?.ordered?.address}</span>
                    <br />
                    <span style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;:&ensp;{siparis?.listOrderDetail
                    [0]?.ordered?.phone}</span>
                    <br />
                </div>
                <hr style={{ border: "2px solid black" }} />
                <span style={{ color: "black", fontWeight: "bold", fontSize: "5mm" }}>Gönderi Tipi&emsp;:&ensp;<span style={{ fontSize: "5mm" }}>{egcTeknik.shipmentType}</span></span>

                <hr style={{ border: "2px solid black" }} />
                <div className="col-12 md:col-12 ">
                    <span style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>Gönderici&emsp;&emsp; :&ensp;{egcTeknik.name}</span>
                    <br />
                    <span style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;:&ensp;{egcTeknik.address}</span>
                    <br />
                    <span style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;:&ensp;{egcTeknik.phone}</span>
                    <br />
                </div>
                <br /> <br />
                <div className="col-12 md:col-12 ">
                    <span style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>Sipariş No&emsp;&emsp;:&ensp;{siparis.orderId}</span><br />
                    <span style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>Tarihi&emsp;&emsp;&emsp;&emsp; :&ensp;{new Date(siparis.orderDate).toLocaleString().split(' ')[0]}</span>
                    <br />

                </div>
                <hr style={{ border: "2px solid black" }} />

                <table className="table table-dark" cellPadding="0" cellSpacing="0">

                    <tbody>
                        <tr className="heading">
                            <td style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>Ürünler</td>
                            <td style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>Stok Kodu</td>
                            <td style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>Adet</td>
                            <td style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>Fiyat</td>
                            <td style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>İndirim Orn.</td>
                            <td style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>Toplam Fiyat</td>
                        </tr>
                        {

                            siparis.listOrderDetail.map((item) =>
                                <tr className="item" >
                                    <td style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>{item.product.productName} </td>
                                    <td style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>{item.product.stock_code} </td>
                                    <td style={{ color: "red", fontWeight: "bold", fontSize: "4mm", }}>{item.quantity + '  X'}</td>
                                    <td style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>{item.price} TL</td>
                                    <td style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>{item.discount > 0 ? `% ${item.discount}` : '-'}</td>
                                    <td style={{ color: "black", fontWeight: "bold", fontSize: "3mm", }}>{item.discount > 0 ? (item.price - (item.price * item.discount / 100)).toFixed(2) : item.price}</td>
                                    
                                </tr>
                            )
                            
                        }
                    </tbody>
                </table>

                <table cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr className="total">
                            <td></td>
                            {siparis.deliveredOffice ? 
                             <td style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>Teslimat : OFİSTEN TESLİM</td>
                            : <td style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>Kargo : {siparis.paid > 400 ? 'Bedava' : '45.99 TL'}</td>}
                           
                            
                        </tr>
                        <tr className="total">
                        <td></td>
                        {siparis.proCodePrice> 0 ? 
                             <td style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>% {siparis.proCodePrice} Promosyon Kodu Uygulandı.</td>
                            : <></>}
                        </tr>
                        <tr className="total">
                            <td></td>
                            <td style={{ color: "black", fontWeight: "bold", fontSize: "3mm" }}>Toplam : {siparis.paid}TL</td>
                        </tr>

                    </tbody>
                </table>

            </div>

            <div className="card">
                <div className="grid p-fluid">

                    <div className="field col-12 md:col-6" >
                        <Button onClick={handlePrint}> Yazdır </Button>

                    </div>
                    <div className="field col-12 md:col-6">

                        <Pdf targetRef={componentRef} filename="siparişFişi.pdf">
                            {({ toPdf }) => (
                                <Button className=" p-button-raised p-button-success mr-2" onClick={toPdf}>
                                    PDF İndir
                                </Button>
                            )}
                        </Pdf>
                    </div>

                </div>
            </div>

        </div >
    );
}
export default SiparisFisi;
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import axios from 'axios';



const AlarmListesi = () => {

    const [alarms, setAlarms] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [productList, setProductList] = useState(null);
    const [haberVer, setHaberVer] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [alarmDialog, setAlarmDialog] = useState(false);





    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}Product/GetProductList`).then((response) => {
            var son = response?.data?.data.filter(obj => obj.alarmsayisi > 0)
            var alarm = son.sort((a, b) => {
                return b.alarmsayisi - a.alarmsayisi;
            });
            setProductList(alarm)
        })
        axios.get(`${process.env.REACT_APP_API_URL}Product/GelinceHaberver`).then((response) => {
            var son = response?.data?.data.filter(obj => obj.count > 0)
            var stok = son.sort((a, b) => {
                return b.count - a.count;
            });
            setHaberVer(stok)
            console.log("test", stok)
        })
    }, [])

    // useEffect(() => {
    //     axios.post(`${process.env.REACT_APP_API_URL}Product/ProductAlarmList?productId=${productId}`).then((response) => {
    //         setCustomers(response?.data?.data)
    //         console.log("response2", response);
    //     })
    // }, [])




    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">ALARM İŞLEMLERİ</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ürün Ara..." />
            </span>
        </div>
    );
    const headers = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Gelince Haber ver Listesi</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ürün Ara..." />
            </span>
        </div>
    );
    const imageBodyTemplate = (rowData) => {
        return (
            <>
                {/* <span className="p-column-title">Image</span> */}
                <img src={rowData.images[0]?.name} className="shadow-1 round" style={{ borderRadius: '27px' }} width="50" />
            </>
        )
    }
    const kampanyaTemplate = (rowData) => {
        return (
            <>
                {rowData.campaigns == 1 ? "Evet" : "Hayır"}
            </>
        )
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-bell" className="p-button-rounded p-button-success mr-2" onClick={() => alarmGoster(rowData)} />
            </div>
        );
    }
    const alarmGoster = (rowData) => {

        setAlarmDialog(true)
        axios.post(`${process.env.REACT_APP_API_URL}Product/ProductAlarmList?productId=${rowData.productId}`).then((response) => {
            setAlarms(response?.data?.data)
            if (response?.data?.data !== null || undefined) {
                console.log("23221212", response);


            }
            console.log("response212", response);
        })

    }
    const statusBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.status == true ? "Kullanımda" : "Kullanımda Değil"}
            </>
        )
    }
    const sendDate = (rowData) => {
        return (
            <>
                {new Date(rowData.sendDate).toLocaleString().split(' ')[0]}
                {/* {rowData.orderDate.split("T")[0]} */}

            </>
        );
    }
    const allowExpansion = (rowData) => {
        console.log("rowData23", rowData);
        return rowData?.productId !== null;
    };
    const rowExpansionTemplate = (data) => {
        console.log("data", data);
        return (
            <div className="orders-subtable">
                {/* <h5>Orders for {da  ta.name}</h5> */}
                <DataTable
                    showGridlines
                    value={alarms}
                    responsiveLayout="scroll">
                    <Column field="productDescription" header="Müşteri Id" body={(e) => e.productDescription} ></Column>
                    <Column field="sendDate" header="Oluşturulma Tarihi" body={sendDate} sortable></Column>
                    <Column field="productName" header="Alarm Fiyatı" body={(e) => e.productName} ></Column>
                    <Column field="unitPrice" header="Ürün Fiyatı" body={(e) => e.product.unitPrice} ></Column>
                </DataTable>
            </div>
        );
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <DataTable
                        ref={dt}
                        value={haberVer}
                        showGridlines
                        dataKey="productID"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen ürün {first} ile {last} arasında, toplam ürün sayısı : {totalRecords} "
                        globalFilter={globalFilter} emptyMessage="Ürün bulunamamıştır." header={headers} responsiveLayout="scroll">
                        <Column field="productID" header="Ürün Id" style={{ minWidth: '5rem' }} body={(e) => e.productID}></Column>
                        <Column field="productName" header="Ürün Adı" style={{ minWidth: '25rem' }} body={(e) => e.productName}></Column>
                        <Column field="count" style={{ minWidth: '3rem' }} header="Talep Sayısı" sortable body={(e) => e.count}></Column>
                    </DataTable>





                </div>
            </div >
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} position="top-center" />
                    <Dialog
                        header="Sipariş Bilgileri"
                        visible={alarmDialog}
                        modal
                        style={{ width: '50vw' }}
                        onHide={() => setAlarmDialog(false)}
                    // footer={orderFooter}

                    >
                        <div className="card">
                            <DataTable
                                showGridlines
                                value={alarms}
                                emptyMessage="Alarm Bulunamamıştır."
                                responsiveLayout="scroll">
                                <Column field="productDescription" header="Müşteri Id" body={(e) => e.productDescription} ></Column>
                                <Column field="sendDate" header="Oluşturulma Tarihi" body={sendDate} sortable></Column>
                                <Column field="productName" header="Alarm Fiyatı" body={(e) => e.productName} ></Column>
                                <Column field="unitPrice" header="Ürün Fiyatı" body={(e) => e.product.unitPrice} ></Column>
                            </DataTable>
                        </div>


                    </Dialog>


                    <DataTable
                        ref={dt}
                        value={productList}
                        selection={selectedProducts}
                        showGridlines
                        onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="productId"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen ürün {first} ile {last} arasında, toplam ürün sayısı : {totalRecords} "
                        globalFilter={globalFilter} emptyMessage="Ürün bulunamamıştır." header={header} responsiveLayout="scroll">
                        <Column field="productId" header="Ürün Id" style={{ minWidth: '5rem' }} body={(e) => e.productId}></Column>
                        <Column header="Resim" style={{ minWidth: '5rem' }} body={imageBodyTemplate}></Column>
                        <Column field="productName" header="Ürün Adı" style={{ minWidth: '25rem' }} body={(e) => e.productName}></Column>
                        <Column field="alarmsayisi" style={{ minWidth: '3rem' }} header="Alarm Sayısı" sortable body={(e) => e.alarmsayisi}></Column>
                        <Column field="quantityPerUnit" style={{ minWidth: '3rem' }} header="Stok" sortable body={(e) => e.quantityPerUnit}></Column>
                        <Column field="unitPrice" header="Satış Fiyat" style={{ minWidth: '5rem' }} sortable body={(e) => e.unitPrice}></Column>
                        <Column field="purchasePrice" header="$ Karşılığı" style={{ minWidth: '5rem' }} sortable body={(e) => e.purchasePrice}></Column>

                        {/* <Column field="basePrice" header="Alış Fiyat" style={{ width: '5rem' }} sortable body={(e) => e.basePrice}></Column> */}

                        {/* <Column field="stock_code" header="Stok Kodu" style={{ width: '5rem' }} body={(e) => e.stock_code}></Column> */}
                        <Column field="barcode" header="Barcode" style={{ minWidth: '5rem' }} body={(e) => e.barcode}></Column>
                        <Column field="platform" header="Platform Id" style={{ minWidth: '5rem' }} body={(e) => e.process_id}></Column>
                        <Column field="" header="Kampanya Durumu" style={{ width: '3rem' }} body={kampanyaTemplate} />
                        {/* <Column field="productDay" header="Günün Ürünü" style={{ width: '5rem' }} body={(productDayTemplate)}></Column> */}
                        <Column header="Aktif Durum" style={{ width: '5rem' }} body={(statusBodyTemplate)}></Column>

                        <Column header="Alarm Göster" style={{ minWidth: '4rem' }} bodyStyle={{ textAlign: 'center' }} body={actionBodyTemplate} ></Column>
                    </DataTable>





                </div>
            </div >

        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AlarmListesi, comparisonFn);
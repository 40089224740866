import React, { useState, useEffect, useRef } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';



const StokRaporu = () => {
    const [dataYenile, SetDataYenile] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [satislar, SetSatislar] = useState([]);
    const [bastar, SetBasTar] = useState(new Date())
    const [bittar, SetBitTar] = useState(new Date())
    const [loadingIcon, SetLoadingIcon] = useState("pi pi-search")
    useEffect(() => {
        list();
    }, []);


    const list = () => {
        SetSatislar([])
        const endDate = new Date();
        const startDate = new Date();
        endDate.setDate(endDate.getDate() + 1);
        const params = {
            bastar: startDate.toISOString().split('T')[0],
            bittar: endDate?.toISOString().split('T')[0]
        };
        axios.get(`${process.env.REACT_APP_API_URL}Stok/GetStockMovements`, { params }).then((response) => {
            if (response != null) {
                SetSatislar(response?.data?.data);
            }
        });
    }

    const clicklist = () => {
        if (bastar > bittar) {
            toast.current.show({ severity: 'warn', summary: `Uyarı`, detail: ' Başlangıç tarihi bitiş tarihinden büyük olamaz!', life: 3000 });
        } else {
            SetLoadingIcon("pi pi-spin pi-spinner")
            SetSatislar([]);
            const params = {
                bastar: bastar?.toISOString().split('T')[0],
                bittar: bittar?.toISOString().split('T')[0]
            };
            axios.get(`${process.env.REACT_APP_API_URL}Stok/GetStockMovements`, { params }).then((response) => {
                if (response != null) {
                    SetSatislar(response?.data?.data);
                    SetLoadingIcon("pi pi-search")
                }
            });
        }

    }


    const imageSatisTemplate = (rowData) => {
        return (
            <>
                <Image src={rowData.picture} preview className="shadow-1 round" style={{ borderRadius: '27px' }} width="35" />
            </>
        )
    }


    const islemtipi = (rowData) => {
        if (rowData.movementType == "Satış")
            return <Tag severity="success" icon="pi pi-check" value={rowData.movementType}></Tag>
        if (rowData.movementType == "Stok Girişi")
            return <Tag severity="warning" icon="pi pi-arrow-right" value={rowData.movementType}></Tag>
    }
    const onOrderDate = (e, type) => {
        if (type == 'bastar') {
            e.setHours(e.getHours() + 4);
            let bastar = e.toISOString();
            SetBasTar(e);
        }
        if (type == 'bittar') {
            e.setHours(e.getHours() + 4);
            let bittar = e.toISOString();
            SetBitTar(e);
        }
    }


    const satisDate = (rowData) => {
        return (
            <>
                {new Date(rowData.movementDate).toLocaleDateString()}
            </>
        );
    }
    const rowClassName = (rowData) => {
        return { 'p-highlight': (rowData.movementType === "Stok Girişi") };
    }

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(satislar);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            workbook.Sheets.data.A1.v = "KAYIT NUM.";
            workbook.Sheets.data.B1.v = "İŞLEM TARİHİ";
            workbook.Sheets.data.C1.v = "ÜRÜN NUMARASI";
            workbook.Sheets.data.D1.v = "ÜRÜN ADI";
            workbook.Sheets.data.E1.v = "ÜRÜN RESMİ";
            workbook.Sheets.data.F1.v = "SAYI";
            workbook.Sheets.data.G1.v = "ANLIK STOK";
            workbook.Sheets.data.H1.v = "İŞLEM TİPİ(Stok Girişi - Satış )";
            workbook.Sheets.data.I1.v = "FİYAT ";
            workbook.Sheets.data.J1.v = "NOT ";
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'satislar');
        });
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const rigthContent = (
        <React.Fragment>
            <Button
                type="button"
                label='Excel Listesi Al'
                icon="pi pi-file-excel"
                className="p-button-success mr-2"
                onClick={exportExcel}
                data-pr-tooltip="XLS" />
        </React.Fragment>
    );

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center p-0">
            <h5 className="m-0 pl-5">Satış StokRaporu</h5>
            <span className="block mt-1 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ürün Ara..." />
            </span>
        </div>
    );


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <i className="pi pi-calendar-minus p-toolbar-separator mr-2" />
                <span className='mr-2'>Baslangıç Tarihi :  </span>
                <Calendar id="description"
                    placeholder='Tarih Seçiniz '
                    value={bastar}
                    onChange={(e) => onOrderDate(e.target.value, 'bastar')}
                    dateFormat="dd.mm.yy" />
                <i className="pi pi-calendar-minus p-toolbar-separator mr-2" />
                <span className='mr-2'>Bitiş Tarihi :  </span>
                <Calendar id="description"
                    locale='en'
                    placeholder='Tarih Seçiniz '
                    value={bittar}
                    onChange={(e) => onOrderDate(e.target.value, 'bittar')}
                    dateFormat="dd.mm.yy" />
                <Button icon={loadingIcon} label='SORGULA' onClick={clicklist} className="p-button p-button-secondary ml-2" badge="Tarih Aralığında Sorgula" />


            </React.Fragment>
        )
    }
    return (
        <div className="grid crud-demo no-gutters">
            <Toast ref={toast} position="bottom-right" />
            <div className="col-12">
                <Toolbar className="col-12 mb-1" left={leftToolbarTemplate} right={rigthContent}  ></Toolbar>
                <div className="card p-2 bg-green-50">
                    <DataTable
                        className="datatable-responsive"
                        size='small'
                        emptyMessage="Ürün bulunamamıştır."
                        responsiveLayout="scroll"
                        rows={50}
                        rowsPerPageOptions={[50, 100, 200, 300]}
                        showGridlines
                        paginator
                        stripedRows
                        globalFilter={globalFilter}
                        header={header}
                        rowHover
                        value={satislar}
                        rowClassName={rowClassName}
                        id="movementID" >
                        <Column headerStyle={{ width: '1rem' }} field="movementID" header="#"></Column>
                        <Column header="Resim" bodyStyle={{ textAlign: 'center' }} headerStyle={{ width: '1rem' }} body={imageSatisTemplate}></Column>
                        <Column field="productName" header="Ürün Adı"></Column>
                        <Column body={islemtipi} header="İşlem Tipi" bodyStyle={{ textAlign: 'center' }} headerStyle={{ width: "10%", textAlign: 'center' }}></Column>
                        <Column field="quantity" header="Satış Adet" bodyStyle={{ textAlign: 'center' }} headerStyle={{ width: "10%", textAlign: 'center' }}   ></Column>
                        <Column field="uniqueDate" header="Anlık Kalan Adet" bodyStyle={{ textAlign: 'center' }} headerStyle={{ width: "10%", textAlign: 'center' }}   ></Column>
                        <Column body={satisDate} header="Satış Tarihi" headerStyle={{ width: "10%", textAlign: 'center' }}></Column>
                        <Column field="not" header="İşlem Notu" headerStyle={{ width: "23%", textAlign: 'center' }}></Column>
                    </DataTable>

                </div>

            </div>
        </div >
    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};
export default React.memo(StokRaporu, comparisonFn);
import React from 'react';
import { Link, Routes, Route } from 'react-router-dom';

import { Tooltip } from 'primereact/tooltip';

import './App.scss';




export const AppTopbar = (props) => {


    const cikisYap = () => {
            window.location.reload()
            sessionStorage.removeItem('ka')
    }

    return (
        <div className="layout-topbar">
            <Tooltip target=".custom-target-icon" />


            <Link to="/" className="layout-topbar-logo img">
                <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-4.png' : 'assets/layout/images/logo-4.png'} alt="logo" />
            </Link>
            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>
           
            <button  className="layout-topbar-button" style={{borderRadius:"1%",border:"1px solid"}} onClick={cikisYap}>
                <i className="pi pi-sign-out" />
                <span >Çıkış Yap</span>
            </button>
        </div>
    );
}

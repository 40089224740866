import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { MultiSelect } from 'primereact/multiselect';
import './DataTableDemo.css';

import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import axios from 'axios';
import { InputNumber } from 'primereact/inputnumber';
import { addLocale } from 'primereact/api';




const KurYonetimi = () => {

    const [kur, setKur] = useState([]);
    const [mevcutKur, setMevcutKur] = useState([]);
    const [yenile, setYenile] = useState(false);
    const [visibleKur, setVisibleKur] = useState(false);
    const [visibleKurDurdur, setVisibleKurDurDur] = useState(false);
    const [categoryList, setCategoryList] = useState();
    const [categories, setCategories] = useState([]);
    const [kurGetir, setKurGetir] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}TaskScheduling/GetKurBilgisi`).then((response) => {
            setKur(response?.data?.data)
            setMevcutKur(response?.data?.data)
        })
    }, [yenile])

    useEffect(() => {
        categoryList?.map((category) => {
            let kategori = { ...categories, name: category.categoryName, code: category.categoryId }
            categories.push(kategori)

        })
    }, [categoryList])


    console.log("categories", categories);
    addLocale('en', {
        firstDayOfWeek: 1,
        dayNames: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
        // dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['P', 'P', 'S', 'Ç', 'P', 'C', 'C'],
        monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
        // monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Gün',
        // clear: 'Limpiar'
    });

    const openNew = () => {
        setKurGetir(true)
       
    }
    const kuruDurdur = () => {

        axios.get(`${process.env.REACT_APP_API_URL}TaskScheduling/GetOtoKurGuncelle`).then((res) => {
            console.log("res12341", res);
            if (res.data.messages == "Kur Otomatik Başlatıldı.") {
                setYenile(!yenile)
                toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Dolar Kuru Otomatik Olarak Çekilmektedir.', life: 3000 });

            } else {
                setYenile(!yenile)

                toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Dolar Kuru Otomatik Çekme İşlemi Durdurulmuştur.', life: 3000 });

            }
        })



    }




    const guncelleKurBilgisi = (rowData) => {
        console.log("kur nedir ", kur);
        let _kur = { ...kur };
        let tarih = new Date()
        tarih.setHours(tarih.getHours() + 3)
        let tarih1 = tarih.toISOString();
        _kur.ekAlan3 = tarih1;
        setKur(_kur);

        axios.post(`${process.env.REACT_APP_API_URL}TaskScheduling/GetKurUpdate`, kur).then((response) => {
            console.log("response25525", response);
            toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Dolar Kuru Güncellendi', life: 2000 });

            setYenile(!yenile)
            setKurGetir(false)

        })
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        console.log("valval1", val);
        let _kur = { ...kur };
        _kur[`${name}`] = val;
        setKur(_kur);
    }



    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="mr-1">
                    <Button disabled={kurGetir} label="Dolar Kurunu Getir " style={{ witdh: "25px" }} className="p-button-success mr-2" onClick={openNew} />
                </div>

            </React.Fragment>
        )
    }
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>

                <div className="mr-1">
                    <Button label={kur?.ekAlan4 == "KuyrukKapalı" ? "Otomatik Kuru Başlat " : "Otomatik Kuru Durdur"}
                        className={kur?.ekAlan4 == "KuyrukKapalı" ? "p-button-success mr-2" : "p-button-danger mr-2"} onClick={() => setVisibleKurDurDur(true)} />
                </div>

            </React.Fragment>
        )
    }
    const onHideDurdurKur = () => {
        console.log("kapat dialog");
        setVisibleKur(false);
    }
    const onHide = () => {
        console.log("kapat dialog");
        setVisibleKur(false);
    }
    return (
        <div className="datatable-rowexpansion-demo">
            <div className="card" style={{ width: '45rem', margin: "0 auto", marginBottom: '2em', height: '45rem', }}>
                <Toast ref={toast} position="top-center" />
                <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>

                {/* <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar> */}
                {
                    kurGetir ? (
                        <>
                            <div className="p-fluid grid formgrid">
                                <div className="field col-12 md:col-6">
                                    <span htmlFor="dolarKur">Mevcut Kur Bilgisi</span>
                                    <InputText
                                        id="dolarKur"
                                        value={mevcutKur.ekAlan2}
                                        // onChange={(e) => onInputChange(e, 'ekAlan2')}
                                        type="text"
                                        disabled />


                                </div>
                                <div className="field col-12 md:col-6">
                                    <span htmlFor="dolarKur">Değiştirilme Tarihi</span>
                                    <InputText
                                        id="dolarKur"
                                        value={mevcutKur.ekAlan3}
                                        // onChange={(e) => onInputChange(e, 'ekAlan2')}
                                        type="text"
                                        disabled />

                                </div>
                                <br />
                                <div className="field col-12 md:col-12">
                                    <span htmlFor="dolarKur">Yeni Dolar Kuru Giriniz </span>
                                    <InputText
                                        id="dolarKur"
                                        value={kur.ekAlan2}
                                        onChange={(e) => onInputChange(e, 'ekAlan2')}
                                        type="text" />
                                </div>




                                <div className="field col-12 md:col-12">

                                    <Button
                                        label="Güncelle"
                                        icon="pi pi-check"
                                        className="p-button-warning mr-2"
                                        onClick={() => setVisibleKur(true)} />
                                </div>




                            </div>

                        </>
                    ) : (
                        <>
                        </>
                    )
                }

                <ConfirmDialog visible={visibleKurDurdur} onHide={() => setVisibleKurDurDur(false)}
                    message="Otomatik Dolar Kuru Çekme İşlemini Durdurmak İstediğinize Emin Misiniz ?"
                    header="Dikkat ! " icon="pi pi-exclamation-triangle" rejectLabel="Hayır" acceptLabel="Evet" accept={kuruDurdur} reject={onHideDurdurKur} />




                <ConfirmDialog visible={visibleKur} onHide={() => setVisibleKur(false)}
                    message="Dolar Kurunu Güncellemek İstediğinize Emin Misiniz ?"
                    header="Dikkat ! " icon="pi pi-exclamation-triangle" rejectLabel="Hayır" acceptLabel="Evet" accept={guncelleKurBilgisi} reject={onHide} />



            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(KurYonetimi, comparisonFn);
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import { SelectButton } from 'primereact/selectbutton';
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
const VitrinYonetimi = () => {
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [alarmDialog, setAlarmDialog] = useState(false);
    const toast = useRef(null);
    const sizeOptions = [
        { name: 'Fırsat Ürünler', code: 'Fırsat Ürünler' },
        { name: 'Populer Ürünler', code: 'Populer Ürünler' },
        { name: 'Çok Satan Ürünler', code: 'Çok Satan Ürünler' },
        { name: 'İndirimli Ürünler', code: 'İndirimli Ürünler' },
        { name: 'Yeni Ürünler', code: 'Yeni Ürünler' },
        { name: 'Kasa Önü Ürünler', code: 'Kasa Önü Ürünler' },
        { name: 'Filament', code: 'Filament' },
        { name: 'Nozzle', code: 'Nozzle' },
    ];
    const [yenile, setYenile] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [productList, setProductList] = useState(null);
    const [urunList, setUrunList] = useState(null);
    const [selectedurunList, setSelectedurunList] = useState(null);
    const [dialog, setDialog] = useState(false);
    const [visible, setVisible] = useState(false);
    const [vitrinSave, setVitrinSave] = useState(ProductSave);
    const [globalFilter, setGlobalFilter] = useState(null);


    const ProductSave = {
        productListID: 0,
        productID: 0,
        listType: "",
        sortOrder: 0
    }

    const openProductList = () => {
        setDialog(true);
        setSelectedurunList(null);
        setSelectedOptions(null);
        let save = { ...vitrinSave };
        save[`sortOrder`] = 0;
        setVitrinSave(save);
    }

    const closeProductList = () => {
        setDialog(false);
        setSelectedurunList(null);
        setSelectedOptions(null);
    }


    const saveProductList = () => {
        if ((selectedurunList == null && selectedurunList == undefined) || (selectedOptions == null && selectedOptions == undefined)) {
            toast.current.show({ severity: 'error', summary: 'Boş Alanları Doldurunuz', detail: 'Lütfen Seçim Yapınız.', life: 2000 });
            return
        }
        if (vitrinSave?.sortOrder == null && vitrinSave?.sortOrder == undefined) {
            toast.current.show({ severity: 'error', summary: 'Boş Alanları Doldurunuz', detail: 'Lütfen Seçim Yapınız.', life: 2000 });
            return
        }
        ProductSave.listType = selectedOptions.code;
        ProductSave.productID = selectedurunList.id;
        ProductSave.sortOrder = vitrinSave?.sortOrder ?? 0;
        axios.post(`${process.env.REACT_APP_API_URL}ProductList/SetProductList`, ProductSave).then((response) => {
            setYenile(!yenile)
            setDialog(false);
            toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Vitrin Ürünü Eklendi', life: 2000 });
        });
    }




    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}ProductList/GetProductList`).then((response) => {
            setProductList(response?.data?.data);
        })
        axios.get(`${process.env.REACT_APP_API_URL}ProductList/ProductList`).then((response) => {
            setUrunList(response?.data?.data);
        })

    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}ProductList/GetProductList`).then((response) => {
            setProductList(response?.data?.data);
        })
    }, [yenile])

    const representativeBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <img src={rowData.product.picture} width="23" />
            </div>
        );
    };

    const vitrinisimleri = (option) => {
        return <Tag style={{ fontSize: 'medium', background: 'linear-gradient(-225deg,#AC32E4 0%,#7918F2 48%,#4801FF 100%)', textAlign: 'center' }} value={option.listType} severity='success' />;
    };
    const VitrinUrunSilTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-times-circle" className=" p-button-warning mr-1" onClick={() => deleteVitrin(rowData)} />
                {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} /> */}
            </div>
        );
    }
    const deleteVitrin = (product) => {
        let products =
        {
            "productListID": product.productListID,
            "productID": 0,
            "listType": "string",
            "sortOrder": 0
        }
        axios.post(`${process.env.REACT_APP_API_URL}ProductList/DeleteProductList`, products).then((response) => {
            setYenile(!yenile)
            toast.current.show({ severity: 'success', summary: 'BAŞARILI', detail: 'Vitrin Ürünü Silindi', life: 2000 });
        });


    }
    
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Vitrin Ekle" icon="pi pi-save" className="p-button-success" onClick={openProductList} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const footerfooter = (
        <>
            <Button label="İptal Et" icon="pi pi-times" className="p-button-text p-button-danger" onClick={closeProductList} />
            <Button label="Kaydet" icon="pi pi-times" className="p-button-text p-button-success" onClick={() => saveProductList(true)} />

        </>
    );
    const InputChange = (e, name) => {
        let _customer = { ...vitrinSave };
        _customer[`${name}`] = e.value;
        setVitrinSave(_customer);
    }
    return (
        <div className="datatable-rowexpansion-demo">
            <div className="col-12">
                <div className="card">
                    <Toolbar className="mb-1" left={leftToolbarTemplate} ></Toolbar>
                    <Toast ref={toast} />
                    <DataTable
                        showGridlines
                        size='small'
                        className="datatable-responsive"
                        reorderableRows onRowReorder={(e) => setProductList(e.value)}
                        value={productList}
                        rowGroupMode="rowspan"
                        groupRowsBy="listType"
                        sortMode="single"
                        sortField="listType"
                        sortOrder={1}
                        paginator
                        rows={100}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Gösterilen ürün {first} ile {last} arasında, toplam ürün sayısı : {totalRecords} "
                        globalFilter={globalFilter}
                        emptyMessage="Ürün bulunamamıştır."
                        responsiveLayout="scroll">
                        <Column header="Sıra" className='text-center' headerStyle={{ width: '3rem' }} body={(data, options) => options.rowIndex + 1}></Column>
                        <Column field="listType" className='text-center ' header="Vitrin İsimleri" body={vitrinisimleri} style={{ minWidth: '200px' }}></Column>
                        <Column rowReorder style={{ width: '3em' }} />
                        <Column header="Ürün Resmi" body={representativeBodyTemplate}></Column>
                        <Column header="Ürün İsimleri" body={(e) => e.product.productName} style={{ minWidth: '500px' }}></Column>
                        <Column field="sortOrder" className='text-center' header="Sıra" body={(e) => e.sortOrder} ></Column>
                        <Column header="    Sil    " style={{ minWidth: '4rem' }} bodyStyle={{ textAlign: 'center' }} body={VitrinUrunSilTemplate} ></Column>
                    </DataTable>
                </div>
            </div >


            <Dialog
                header="Vitrin Bilgi Girişi"
                visible={dialog}
                maximizable
                onHide={closeProductList}
                footer={footerfooter}
            >
                <div >
                    <div className="p-fluid grid formgrid">

                        <div className="field col-12 md:col-12 ">
                            <label htmlFor="dd-city">Ürün Listesi</label>
                            <Dropdown value={selectedurunList} onChange={(e) => setSelectedurunList(e.value)} options={urunList} optionLabel="name"
                                filter showClear placeholder="Lütfen Ürün Seçin" />

                        </div>
                        <div className="field col-12 md:col-12 ">
                            <label htmlFor="dd-city">Vitrin Kategorisi</label>
                            <Dropdown value={selectedOptions} onChange={(e) => setSelectedOptions(e.value)} options={sizeOptions} optionLabel="name"
                                filter showClear placeholder="Lütfen Vitrin Ürünü Seçin" />

                        </div>
                        <div className="field col-12 ">
                            <span htmlFor="sortOrder">Sıra Numarası</span>
                            <InputNumber
                                id="sortOrder"
                                value={vitrinSave?.sortOrder}
                                onChange={(e) => InputChange(e, 'sortOrder')}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(VitrinYonetimi, comparisonFn);